export var GLOBAL = {
    //url: 'https://fabtisoft.com/api/',
    //url2: 'https://fabtisoft.com/rest/',
    //url3: 'https://fabtisoft.com/'
    url: 'https://gairtaxi.com/api/',
    url2: 'https://gairtaxi.com/rest/',
    url3: 'https://gairtaxi.com/'
    
    

}