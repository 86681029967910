import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { LoginService } from '../common/services/login.service';
import { Tienda } from '../models/tienda';

declare var jQuery: any;
declare var $: any;




@Component({
  selector: 'app-my-dialog-editar-doc',
  templateUrl: './my-dialog-editar-doc.component.html',
  styleUrls: ['./my-dialog-editar-doc.component.css']
})


export class MyDialogEditarDocComponent {

  dataDef = [];
  tiendaList: Tienda[];
  constructor(
    private _loginService: LoginService,
    public thisDialogRef: MatDialogRef<MyDialogEditarDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) { }

  /*onCloseConfirm() {
      this.cart=null;
        localStorage.clear();
          this.thisDialogRef.close('Confirm');

  }*/


  /*onCloseCancel() {
     this.thisDialogRef.close('Cancel');
  }*/
  ngOnInit() {    
    this.dataDef = this._loginService.getDataDef();
    
    
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
      console.log(this.data);
      this.verConductorCombo();
  }

  verConductorCombo(): void {

    this._loginService.conductorCombo().subscribe(
      response => {
        console.log("L I S T A   D E   CONDUCTOR COMBO");
        console.log(response);        
        this.tiendaList = response.data;  
        console.log(this.tiendaList);
        console.log("L I S T A   D E   CONDUCTOR FIN COMBO");
      },
      error => {
        console.log(<any>error);
        //console.log("error 454545.");
        var errorMessage = <any>error;
        if (errorMessage != null) {
          var body = JSON.parse(error._body);          
        }
      }
    )
  }
}